<template>
    <div class="oa-page">
        <HeaderAnnualMeeting />
        <div style="height: 400px;">
            <HeaderOa />
        </div>

        <!-- <div class="wid1200">
            <bread-crumb :pN="pN" :childName="pageName"></bread-crumb>
        </div> -->
        <div class="introduction-paper">
            <div class="top-bg"></div>
            <div class="introduction-wrap">
                <div class="wid1200">
                    <div class="left-introduction">
                        <div class="border-left-wrap"></div>
                        <div class="wid690">
                            <top-banner :bannerData="imgBannerData"></top-banner>
                        </div>
                    </div>
                    <div class="right-introduction">
                        <h6>BRIEF INTRODUCTION</h6>
                        <h5>开放获取简介</h5>
                        <div>
                            <p v-html="oaJJ.content"></p>
                            <button><a @click="goIntro">更多<img src="../assets/img/oa/icon-more.png"
                                        alt="" /></a></button>
                        </div>
                    </div>
                </div>

            </div>

            <div class="wid1200 article-wrap">
                <!-- <com-article :articleData="articleData" :pageName="pageName" @changeSubject="changeArticleSubject"
                    @changeYear="changeArticleYear" @pageChange="articleChange" /> -->
                <div class="com-title">
                    <div class="title-div">
                        <h5>OA论文</h5>
                        <!-- {{articleData.title}} -->
                    </div>
                    <div>
                        <span @click="goArticle">更多<img src="../assets/img/oa/icon-more.png" alt="" /></span>
                    </div>
                </div>
                <ul class="com-article">
                    <li v-for="(item, index) in articleData.articleList" :key="index">
                        <!-- appendixType appendix   -->
                        <div class="is-lock">
                            <div v-if="item.isInner==0">
                                <!-- 库外 -->
                                <img src="../assets/img/lock.png" alt="" />
                            </div>
                            <div v-else>
                                <!-- 库内 -->
                                <img src="../assets/img/lock.png" alt="" />
                            </div>
                        </div>
                        <div class="article-content">
                            <h5 :class="[(item.appendixType == 'pdf' && item.appendix) ? 'cursor-h5' : '']"
                                v-if="item.isInner==0" @click="goDetailArticle(item)">
                                <!-- 库外 -->
                                <!-- 没有附件不能点击 -->
                                <p>{{item.searchTitle ? item.searchTitle :item.title}}</p>
                                <span>{{item.subject}}</span>
                            </h5>
                            <h5 v-else @click="goDetailArticle(item)" class="cursor-h5">
                                <!-- 库内 -->
                                <p>{{item.searchTitle ? item.searchTitle : item.title}}</p>
                                <span>{{item.subject}}</span>
                            </h5>
                            <div>
                                <p class="author" v-if="item.author">作者：{{item.author}}</p>
                                <!-- 所属集刊 -->
                                <p class="time" v-if="item.releaseDate">日期：{{item.releaseDate}}</p>
                                <p class="name" v-if="item.source">来源：{{item.source}}</p>
                            </div>
                        </div>

                    </li>
                </ul>

            </div>

        </div>

        <div class="oa-news-video-data">
            <div class="wid1200">
                <div class="oa-news">
                    <div class="com-title">
                        <div class="title-div">
                            <h5>资讯动态</h5>
                        </div>
                        <div>
                            <span @click="goOa('kfhq_dt')">更多<img src="../assets/img/oa/icon-more.png" alt="" /></span>
                        </div>
                    </div>
                    <ul>
                        <li v-for="item in dynamicData" :key="item.id">
                            <p><a @click="goLink(item)">{{item.title}}</a></p>
                            <!-- <span>{{item.releaseDate}}</span> -->
                        </li>
                    </ul>
                </div>
                <div class="oa-video">
                    <div class="com-title">
                        <div class="title-div">
                            <h5>OA视频</h5>
                        </div>
                        <div>
                            <span @click="goVideoMore('kfhq_sp')">更多<img src="../assets/img/oa/icon-more.png"
                                    alt="" /></span>
                        </div>
                    </div>
                    <div class="oa-video-wrap">
                        <!-- <video :src="videoObj.mediaPath" @click="goVideo()" >
                            您的浏览器不支持 video 标签。
                            </video> -->
                        <img class="cover-img" :src="videoObj.titleImg" alt="" @click="goVideo()" />
                        <h6 @click="goVideo()">{{videoObj.title}}</h6>
                        <p class="date" v-if="videoObj.releaseDate">
                            <img src="../assets/img/oa/time.png" alt="" />
                            {{videoObj.releaseDate}}
                        </p>
                    </div>

                </div>
                <div class="oa-data">
                    <div class="com-title">
                        <div class="title-div">
                            <h5>OA数据集</h5>
                        </div>
                        <div>
                            <span @click="goOa('kfhq_sjj')">更多<img src="../assets/img/oa/icon-more.png" alt="" /></span>
                        </div>
                    </div>
                    <ul>
                        <li v-for="item in collectionData" :key="item.id">
                            <p><a @click="goLink(item)">{{item.title}}</a></p>
                            <!-- <span>{{item.releaseDate}}</span> -->
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="oa-main wid1200">

            <div class="wid1200 book-wrap">
                <!-- <com-book :bookData="bookData" :pageName="pageName" @changeSubject="changeBookSubject"
                    @changeYear="changeBookYear" @pageChange="bookChange" /> -->
                <div class="com-title">
                    <div class="title-div">
                        <h5>{{bookData.title}}</h5>
                    </div>
                    <div class="pagination-wrap">
                        <span class="total-page">共 {{Math.ceil(bookData.total/12)}} 页</span>
                        <el-pagination @current-change="bookChange" :page-size="12" :current-page="currentPage"
                            layout="prev, next" :total="bookData.total">
                            <!-- total, , pager,  -->
                        </el-pagination>
                    </div>
                    <!-- <span>更多<img src="../assets/img/oa/icon-more.png" alt="" /></span> -->

                </div>

                <div class="com-book-wrap">
                    <ul class="com-book">
                        <li v-for="(item, index) in bookData.bookList" :key="index" v-if="index<6"
                            @click="goDetailBook(item)">
                            <img v-if="item.titleImg" :src="item.titleImg" alt="" />
                            <img v-else-if="item.img" :src="item.img" alt="">
                            <img v-else alt="">
                            <!-- <p v-if="item.isInner==0">
                                {{item.name}}
                            </p> -->

                            <p>{{item.title}}</p>
                        </li>

                    </ul>
                    <ul class="com-book">
                        <li v-for="(item, index) in bookData.bookList" :key="index" v-if="index>5"
                            @click="goDetailBook(item)">
                            <img v-if="item.titleImg" :src="item.titleImg" alt="" />
                            <img v-else-if="item.img" :src="item.img" alt="">
                            <img v-else alt="">
                            <!-- <p v-if="item.isInner==0">
                                {{item.name}}
                            </p> -->

                            <p>{{item.title}}</p>

                        </li>
                        <!-- <li>
                            <img src="../assets/img/book.png" alt="" />
                            <p>比较政治学研究</p>
                        </li> -->

                    </ul>
                </div>

            </div>

            <div class="courseware-wrap">
                <div class="left-content">
                    <div class="com-title">
                        <div class="title-div">
                            <h5>OA课件</h5>
                        </div>
                        <div>
                            <span @click="goOa('kfhq_kj')">更多<img src="../assets/img/oa/icon-more.png" alt="" /></span>
                        </div>
                    </div>
                    <ul>
                        <li v-for="item in coursewareData" :key="item.id">

                            <a v-if="item.mediaPath" @click="downloadCourse(item)"><span>pdf</span>
                                {{item.title}}</a>
                            <a v-else style="cursor: auto;"><span>pdf</span>
                                {{item.title}}</a>
                        </li>
                        <!-- <li><a href="#"><img src="../assets/img/oa/icon-pdf.png" alt="" />Local Impacts, Mitigation and Adarpation to Climate change</a></li>
                        <li><a href="#"><img src="../assets/img/oa/icon-pdf.png" alt="" />知源外文知识库 ——“ 第 9 届中国机构知识库学术研讨会”主题报告</a></li>
                        <li><a href="#"><img src="../assets/img/oa/icon-pdf.png" alt="" />国家基因组科学数据中心建设及进展</a></li>
                        <li><a href="#"><img src="../assets/img/oa/icon-pdf.png" alt="" />Local Impacts, Mitigation and Adarpation to Climate change</a></li>
                        <li><a href="#"><img src="../assets/img/oa/icon-pdf.png" alt="" />知源外文知识库 ——“ 第 9 届中国机构知识库学术研讨会”主题报告</a></li> -->
                    </ul>
                </div>

                <div class="right-button">
                    <button class="up-btn" v-if="isUp" @click="handleSub" style="cursor: pointer;">
                        <img src="../assets/img/oa/up1.png" alt="" style="cursor: pointer;" />
                    </button>
                    <button v-else class="up-btn" style="cursor: not-allowed;">
                        <img src="../assets/img/oa/up2.png" alt="" style="cursor: not-allowed;" />
                    </button>

                    <div class="button-wrap">
                        <ul class="button-ul">
                            <li v-for="item in instroData" :key="item.id" @click="goInstro(item)">
                                <a :title="item.title">
                                    {{item.title}}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <button class="down-btn" v-if="isDown" @click="handleAdd($event)">
                        <img src="../assets/img/oa/down1.png" alt="" style="cursor: pointer;" />
                    </button>
                    <button class="down-btn" v-else style="cursor: not-allowed;">
                        <img src="../assets/img/oa/down2.png" alt="" style="cursor: not-allowed;" />
                    </button>
                </div>

            </div>

            <div class="visual-analysis-wrap">
                <div class="com-title">
                    <div class="title-div">
                        <h5>可视化分析</h5>
                    </div>
                    <!-- <div>
                        <span>更多<img src="../assets/img/oa/icon-more.png" alt="" /></span>
                    </div> -->
                </div>

                <div class="top-chart">
                    <div class="left-resourceDisciplineDistributionMap">
                        <h6>资源学科分布图</h6>
                        <resource-discipline :chartData1="chartData1" :chartTotal1="chartTotal1" />
                    </div>
                    <div class="right-word-cloud-chart">
                        <h6>词云图</h6>
                        <div class="right-word-cloud-chart-wrap">
                            <word-cloud :chartData2="chartData2" />
                        </div>

                    </div>
                </div>
                <div class="bot-chart">
                    <h6>产出时间分布图</h6>
                    <output-time :chartData3="chartData3" />
                </div>
            </div>

            <div class="oa-platform-wrap">
                <div class="com-title">
                    <div class="title-div">
                        <h5>OA平台</h5>
                    </div>
                    <div>
                        <span @click="goPlat(4)">更多<img src="../assets/img/oa/icon-more.png" alt="" /></span>
                    </div>
                </div>
                <ul>
                    <li v-for="item in platformData" :key="item.id"><a>
                            <!-- <img src="../assets/img/oa/test1.png" alt="" /> -->
                            <img :src="item.logo" alt="" />
                            <h6>{{item.siteName}}</h6>
                            <p @click="goInPlat(item.domain)">
                                <span>{{item.info}}</span>
                            </p>
                            <!-- item.domain -->
                        </a></li>
                </ul>
            </div>

            <!-- <div class="footer-oa-wrap">
                <footerOa></footerOa>
            </div> -->
        </div>

        <a id="downLaodAticle" style="display:none;" :href="'/admin/api/article/downLoad?id='+downloadId">下载阅读</a>
        <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose"
            :append-to-body="trueDate">
            <div class="tips" v-if="codeNum == '1002'">
                <span v-if="paylist.length>0" style="font-size: 16px;line-height: 30px;">该资源需要付费 {{payPrice}}
                    元，请购买后再操作！</span>
                <span v-else style="font-size: 16px;line-height: 30px;">该资源需要付费 {{payPrice}} 元，账号余额不足请先充值。</span>
            </div>

            <span class="tips  com-tips" v-else-if="tips == '您还未登录，请先登录！'" style="font-size: 16px;line-height: 30px;">
                您还未登录，请先<span class="com-login-tips" @click="toLogin">登录</span>！
            </span>

            <span class="tips  com-tips" v-else v-html="tips"></span>

            <div slot="footer" class="dialog-footer">
                <el-button type="small" @click="showBuy" v-if="codeNum == '1002'">去购买</el-button>
                <el-button type="small" @click="toLogin" v-if="codeNum == '0'&&!hasPay">请登录</el-button>
            </div>
        </el-dialog>
        <el-dialog title="提示" :visible.sync="dialogBuy" width="30%" :before-close="handleBuyClose"
            :append-to-body="trueDate">
            <div class="tips tipsleft">
                <span style="font-size: 16px;line-height: 30px;">该资源需要付费 {{payPrice}}元</span>
                <p style="font-size: 16px;line-height: 30px;">支付方式：</p>
                <p v-for="(item, index) in balanceInfo" :key="index" style="font-size: 16px;line-height: 30px;">
                    <el-radio v-model="radiobuy" :label="item.accounttype" style="font-size: 16px;line-height: 30px;">
                        {{item.accounttype=='common'?'现金账户余额':'充值卡余额'}}{{item.accountbalance}}</el-radio>
                </p>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="small" @click="handleBuyClose">取消</el-button>
                <el-button type="small" @click="gobuy">支付</el-button>
            </div>
        </el-dialog>
        <el-dialog title="提示" :visible.sync="dialogVisibledow" width="30%" :before-close="handleClosedow"
            :append-to-body="trueDate">
            <span class="tips">{{tipsdow}}</span>
            <div slot="footer" class="dialog-footer">
                <el-button type="small" @click="dialogVisibledow = !dialogVisibledow">确定</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import HeaderAnnualMeeting from '../components/HeaderAnnualMeeting'
import HeaderOa from '../components/HeaderOa'
import BreadCrumb from '../components/BreadCrumb'

// import TopBanner from '../components/TopBanner'
import ComArticle from '../components/ComArticle'
import ComBook from '../components/ComBook'
import ResourceDiscipline from '../components/ChartResourceDiscipline'
import WordCloud from '../components/ChartWordCloud'
import OutputTime from '../components/ChartOutputTime'
import FooterOa from '../components/FooterOa'
import TopBanner from '../components/ComBannerOa'

import img1 from '../assets/img/book.png'

export default {
    name: '',
    components: { HeaderAnnualMeeting, HeaderOa, BreadCrumb, TopBanner, ComArticle, ComBook, ResourceDiscipline, WordCloud, OutputTime, FooterOa },
    data() {
        return {
            pN: '',
            pageName: '哲学社会科学成果开放获取平台',
            bannerData: {
                title: '开放获取简介',
                content: '网课是疫情期间大学课程教学的主要形式。调查表明，直播、复合 型网课是主流。大部分大学生对网课适应、基本满意，但在学习效果判断上态度犹豫。大学生普遍反映，网课在“网络速度”“师生互动”等方面需要改进。网络授课也将对大学学习的形式产生深刻影响，但它毕竟难以取代真正的大学学习生活。尽管疫情的发展还有一定不确定性，从调查结果来看，约... ',
            },
            imgBannerData: [],
            dynamicData: [],
            videoObj: [],
            collectionData: [],
            coursewareData: [],
            // otherSourceData: [],
            // otherSourceNum: 1,
            // otherSourceSize: 6,
            // otherSourceTotal: 0,
            articleData: {
                title: 'OA论文',
                articleList: [
                    {
                        title: '井上圆了对近代中国佛教改革理论的影响——以“人间佛教”...',
                        author: '陆学艺',
                        book: '马克思主义哲学研究',
                        time: '2020-09-12',
                    },
                    {
                        title: '井上圆了对近代中国佛教改革理论的影响——以“人间佛教”...',
                        author: '陆学艺',
                        book: '马克思主义哲学研究',
                        time: '2020-09-12',
                    },
                    {
                        title: '井上圆了对近代中国佛教改革理论的影响——以“人间佛教”...',
                        author: '陆学艺',
                        book: '马克思主义哲学研究',
                        time: '2020-09-12',
                    },
                    {
                        title: '井上圆了对近代中国佛教改革理论的影响——以“人间佛教”...',
                        author: '陆学艺',
                        book: '马克思主义哲学研究',
                        time: '2020-09-12',
                    },
                    {
                        title: '井上圆了对近代中国佛教改革理论的影响——以“人间佛教”...',
                        author: '陆学艺',
                        book: '马克思主义哲学研究',
                        time: '2020-09-12',
                    },
                    {
                        title: '井上圆了对近代中国佛教改革理论的影响——以“人间佛教”...',
                        author: '陆学艺',
                        book: '马克思主义哲学研究',
                        time: '2020-09-12',
                    },
                ],
                total: 0,
            },
            bookData: {
                title: 'OA图书',
                bookList: [
                    {
                        img: img1,
                        title: '比较政治学研究',
                    },
                    {
                        img: img1,
                        title: '比较政治学研究',
                    },
                    {
                        img: img1,
                        title: '比较政治学研究',
                    },
                    {
                        img: img1,
                        title: '比较政治学研究',
                    },
                    {
                        img: img1,
                        title: '比较政治学研究',
                    },
                    {
                        img: img1,
                        title: '比较政治学研究',
                    },
                    {
                        img: img1,
                        title: '比较政治学研究',
                    },
                    {
                        img: img1,
                        title: '比较政治学研究',
                    },
                ],
            },
            subjectId: '',
            pageNum: 1,
            pageSize: 10,
            oaJJ: {},

            downloadId: '',
            dialogVisible: false,
            tips: '',
            paylist: [],
            balanceInfo: [],
            payPrice: '',
            radiobuy: '',
            codeNum: '',
            trueDate: true,
            hasPay: false,
            dialogVisibledow: false,
            tipsdow: '',
            shareWechat: false,
            dialogBuy: false,
            isUp: false,
            isDown: false,
            currentPage: 1,
            platformData: [],
            chartData1: [],
            chartTotal1: 0,
            chartData2: [],
            chartData3: [],
            instroData: [],
            instroData1: [
                {
                    id: 0,
                    title: '优质集刊推荐',
                },
                {
                    id: 1,
                    title: '优质集刊推荐',
                },
                {
                    id: 2,
                    title: '优质集刊推荐',
                },
                {
                    id: 3,
                    title: '优质集刊推荐',
                },
                {
                    id: 4,
                    title: '优质集刊推荐',
                },
                {
                    id: 5,
                    title: '优质集刊推荐',
                },
            ],
            activeTitle: '',
        }
    },
    methods: {
        handleSub() {
            let imgBox = document.getElementsByClassName('button-ul')[0]
            if (imgBox.offsetTop > 0 || imgBox.offsetTop == 0) {
                this.isUp = false
            } else {
                if (imgBox.offsetTop + 66 > 0 || imgBox.offsetTop + 66 == 0) {
                    this.isUp = false
                }
                this.isDown = true
                imgBox.style.marginTop = imgBox.offsetTop + 66 + 'px'
            }
        },
        handleAdd() {
            // if(!el.disabled){
            //     el.disabled = true
            //     // el.style.backgroundColor = '#ccc'
            //     // el.style.border = 'none'
            // 	setTimeout(() => {
            // 		el.disabled = false
            // 		//  el.style.backgroundColor = '#1890ff'
            // 		//  el.style.border = '1px solid #1890ff'
            // 	}, 1000)
            // }

            let imgBox = document.getElementsByClassName('button-ul')[0]
            // console.log(55555,imgBox.clientHeight, imgBox.offsetTop)

            // console.log(66666, imgBox.offsetTop)
            var x = -(imgBox.clientHeight - 320) //334)
            //400)
            // console.log(imgBox.clientHeight, 'ffffff', x,'xxxxxx', imgBox.offsetTop)
            // x+66
            if (x > imgBox.offsetTop || x == imgBox.offsetTop) {
                this.isDown = false
            } else {
                // console.log('imgBox.offsetTop', imgBox.offsetTop)
                if (x + 66 > imgBox.offsetTop || x + 66 == imgBox.offsetTop) {
                    this.isDown = false
                }
                this.isUp = true
                imgBox.style.marginTop = imgBox.offsetTop + -66 + 'px'

                // console.log('imgBox.offsetTop', imgBox.offsetTop)
            }
        },

        articleChange(val) {
            this.pageNum = val
            this.getListData('ARTICLE')
        },
        goInstro(item) {
            this.$router.push({ path: '/oaIntro/o', query: { titleId: item.id, id: 'kfhq_gykfhq', pN: encodeURI(this.pageName) } })
        },
        goPlat(ind) {
            this.$router.push({ path: '/platform/p', query: { type: 4, pN: encodeURI(this.pageName) } })
        },
        goArticle() {
            // oaType=OA图书
            // http://jikan.b.com/oaS/o?searchKey=%20&oaType=OA%E5%9B%BE%E4%B9%A6
            this.$router.push({ path: '/oaS/o', query: { oaType: 'OA文章', source: 'oa', pN: encodeURI(this.pageName) } })
        },
        goInPlat(url) {
            window.open(url)
            // this.$router.push({ path: '/ins/i', query: { id: id, pN: encodeURI(this.pageName) } })
        },
        goVideo() {
            //
            if (this.videoObj.jumpUrl) {
                window.open(this.videoObj.jumpUrl, '_blank')
            } else if (this.videoObj.originUrl) {
                window.open(this.videoObj.originUrl, '_blank')
            } else {
                this.$router.push({ path: '/vD/v', query: { id: this.videoObj.id, pN: encodeURI(this.pageName) } })
            }
        },
        goOa(id) {
            this.$router.push({ path: '/news/n', query: { id: id, pN: encodeURI(this.pageName) } })
        },
        goVideoMore(id) {
            this.$router.push({ path: '/video/v', query: { id: id, pN: encodeURI(this.pageName) } })
        },
        goIntro() {
            this.$router.push({ path: '/oaIntro/o', query: { pN: encodeURI(this.pageName), id: 'kfhq_gykfhq' } })
        },
        goLink(item) {
            if (item.jumpUrl) {
                window.open(item.jumpUrl, '_blank')
            } else if (item.originUrl) {
                window.open(item.originUrl, '_blank')
            } else if (item.mediaType == 'pdf' && item.mediaPath) {
                // pdf
                window.open(item.mediaPath, '_blank')
            } else {
                this.$router.push({ path: '/nD/n', query: { id: item.id, pN: encodeURI(this.pageName) } })
            }
        },
        goDetailArticle(item) {
            this.$router.push({ path: '/aD/a', query: { id: item.id, pN: encodeURI(this.pageName) } })
        },
        goDetailBook(item) {
            if (item.resourceType == 'COLLECTED_PAPERS') {
                this.$router.push({ path: '/c/c', query: { id: item.id, pN: encodeURI(this.pageName) } })
            } else if (item.resourceType == 'WENJI' || item.resourceType == 'BOOK') {
                this.$router.push({ path: '/collectionDetail/c', query: { id: item.id, pN: encodeURI(this.pageName) } })
            } else if (item.resourceType == 'ARTICLE') {
                this.$router.push({ path: '/aD/a', query: { id: item.id, pN: encodeURI(this.pageName) } })
            }
        },
        downloadCourse(item) {
            // https://www.jikan.com.cn/admin/api/news/download?id=1103315293
            const a = document.createElement('a')
            a.href = 'https://www.jikan.com.cn/admin/api/news/download?id=' + item.id
            //'https://www.jikan.com.cn/admin/profile/upload/2022/06/28/1656396375865PPT%20Local%20Impacts,%20Mitigation%20and%20Adarpation%20to%20Climate%20change.pdf'
            // a.download = filename
            a.click()
            // window.URL.revokeObjectURL(blobUrl)
        },
        downloadZip(item) {
            const a = document.createElement('a')
            a.href = item.mediaPath
            //'https://www.jikan.com.cn/admin/profile/upload/2022/06/28/1656396375865PPT%20Local%20Impacts,%20Mitigation%20and%20Adarpation%20to%20Climate%20change.pdf'
            // a.download = filename
            a.click()
            window.URL.revokeObjectURL(blobUrl)

            // var a = document.createElement('a');
            //   if (a.click) {
            //     a.href = blobUrl;
            //     a.target = '_parent';
            //     if ('download' in a) {
            //       a.download = filename;
            //     }
            //     (document.body || document.documentElement).appendChild(a);
            //     a.click();
            //     a.parentNode.removeChild(a);
        },
        bookChange(val) {
            this.pageNum = val
            this.getListData('BOOK')
        },
        changeBookSubject(val) {
            //学科
            this.pageNum = 1
            this.bookSubjectId = val
            this.getListData('BOOK')
        },
        changeBookYear(val) {
            //年份
            this.pageNum = 1
            this.bookBookReleaseDate = val
            this.getListData('BOOK')
        },
        changeArticleSubject(val) {
            //学科
            this.pageNum = 1
            this.articleSubjectId = val
            this.getListData('ARTICLE')
        },
        changeArticleYear(val) {
            //年份
            this.pageNum = 1
            this.articleBookReleaseDate = val
            this.getListData('ARTICLE')
        },
        getData(id) {
            var _this = this
            var curParams = {}
            // if (id == 'kfhq_qtzy') {
            //     curParams = {
            //         innerCode: id,
            //         pageSize: this.otherSourceSize,
            //         pageNum: this.otherSourceNum,
            //     }
            // } else {
            curParams = {
                innerCode: id,
            }
            // }
            this.$http({
                method: 'get',
                url: '/admin/api/news/list',
                params: curParams,
            }).then((res) => {
                if (res.data.code == 0) {
                    if (id == 'kfhq_lbt') {
                        //轮播图
                        _this.imgBannerData = res.data.data.rows
                    } else if (id == 'kfhq_dt') {
                        //动态
                        _this.dynamicData = res.data.data.rows.slice(0, 7)
                    } else if (id == 'kfhq_sp') {
                        _this.videoObj = res.data.data.rows[0]
                    } else if (id == 'kfhq_sjj') {
                        //数据集
                        _this.collectionData = res.data.data.rows.slice(0, 7)
                    } else if (id == 'kfhq_kj') {
                        _this.coursewareData = res.data.data.rows.slice(0, 6)
                        //} else if (id == 'kfhq_qtzy') {
                        //    //其他资源
                        //    _this.otherSourceData = res.data.data.rows
                        //    _this.otherSourceTotal = res.data.data.total
                        //    // console.log('otherSourceData', _this.otherSourceData)
                    } else if (id == 'kfhq_jj') {
                        //简介
                        _this.oaJJ = res.data.data.rows[0]
                    }
                }
            })
        },
        getListData(type) {
            var _this = this
            if (this.bookReleaseDate == '全部') {
                this.bookReleaseDate = ''
            }
            var curYear = ''
            var curSubject = ''
            var resourceTypeList = []
            if (type == 'BOOK') {
                curYear = this.bookBookReleaseDate
                curSubject = this.bookSubjectId
                this.pageSize = 12
                resourceTypeList = ['BOOK', 'WENJI']
            } else if (type == 'ARTICLE') {
                curYear = this.articleBookReleaseDate
                curSubject = this.articleSubjectId
                this.pageSize = 8
                resourceTypeList = ['ARTICLE']
            }

            this.$http({
                method: 'post',
                url: '/admin/api/search/find',
                data: {
                    pageSize: this.pageSize,
                    pageNo: this.pageNum,
                    openAccessStatus: 1,
                    resourceTypeList: resourceTypeList,
                    classifyId: curSubject,
                    bookReleaseDate: curYear,
                },
            }).then((res) => {
                if (res.data.code == 0) {
                    if (type == 'BOOK') {
                        _this.bookData.total = res.data.data.total
                        _this.bookData.bookList = res.data.data.rows
                    } else if (type == 'ARTICLE') {
                        _this.articleData.total = res.data.data.total
                        _this.articleData.articleList = res.data.data.rows
                    }
                }
            })
        },

        download(item) {
            // console.log('item', item)

            // mediaPath: "/admin/profile/upload/2020/12/23/4a6ff08ff98ab7f34bcd4cb11b4ae2bf.rar,/admin/profile/upload/2020/12/23/4a6ff08ff98ab7f34bcd4cb11b4ae2bf.rar"
            // mediaType: "rar"

            this.downloadId = item.id
            var _this = this
            // _trackData.push(['addaction','期刊主页', '全部下载按钮', {'标题': _this.detail.title}])
            var result = _this.getCheck(2)
            result
                .then(function (res) {
                    if (res.data.code == 0) {
                        if (item.mediaType == 'pdf') {
                            window.open(item.mediaPath, '_blank')
                        } else {
                            document.getElementById('downLaodAticle').click()
                        }
                    }
                })
                .catch(function (res) {
                    _this.dialogVisible = true
                    if (res.code == 401) {
                        //未登录
                        _this.tips = '您还未登录，请先登录！'
                    }
                    if (res.code == 1002) {
                        //无权限
                        _this.paylist = error.paylist
                        _this.balanceInfo = error.balanceInfo
                        _this.payPrice = error.price
                        _this.tips = '这个资源需要付费，请购买后查看！'
                    }
                    if (res.code == 500) {
                        //统一错误
                        _this.tips = res.msg
                    }
                })
        },
        handleBuyClose() {
            this.dialogBuy = false
        },
        handleClosedow() {
            this.dialogVisibledow = false
        },
        handleClose() {
            this.dialogVisible = false
        },
        showBuy() {
            this.dialogBuy = !this.dialogBuy
        },
        encodeURI2() {
            var url = encodeURIComponent(window.location.origin + encodeURIComponent(window.location.pathname) + window.location.search + window.location.hash)
            console.log(url)
            return url
        },
        toLogin() {
            window.location.href = '/admin/login.za?ZAS_referer=' + this.encodeURI2()
        },
        gobuy() {
            let _this = this
            _this.winUrl = '/admin/login.za?ZAS_referer=' + this.encodeURI2()
            _this
                .$http({
                    method: 'get',
                    url: '/admin/api/order/submit',
                    params: {
                        contentId: _this.downloadId,
                        payment: _this.radiobuy,
                        resourceType: 'literature',
                    },
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                    },
                })
                .then((res) => {
                    if (res.data.code == 0) {
                        _this.codeNum = 0
                        _this.codeNum2 = false
                        _this.dialogBuy = false
                        _this.hasPay = true
                        _this.tips = '支付完成！'
                    } else {
                        _this.dialogVisibledow = true
                        _this.tipsdow = res.msg
                    }
                })
        },
        getCheck(type) {
            let _this = this
            var promise = new Promise(function (resolve, reject) {
                _this.$http
                    .get('/admin/api/order/check', {
                        params: {
                            id: _this.downloadId, //this.downloadId
                            type: type,
                            resourceType: 'literature',
                        },
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                        },
                    })
                    .then((res) => {
                        _this.codeNum = res.data.code
                        if (res.data.code == 0) {
                            resolve(res)
                        } else {
                            reject(res.data)
                        }
                    })
            })
            return promise
        },
        getPlatform() {
            let _this = this
            this.$http
                .get('/admin/api/index/friendLinks', {
                    params: {
                        type: 4, //  类型  2:国内预印本平台 3:国外预印本平台 4:OA平台
                        pageNum: 1, //页码
                        pageSize: 10, //每页条数
                    },
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                    },
                })
                .then((res) => {
                    if (res.data.code == 0) {
                        _this.platformData = res.data.data.rows
                    }
                })
        },
        getResourceChart() {
            let _this = this
            this.$http({
                method: 'post',
                url: '/admin/api/search/filterCondition',
                data: {
                    oaType: 'ALL',
                    //pageSize: this.pageSize,
                    //pageNo: this.pageNum,
                    //openAccessStatus: 1,
                    //resourceTypeList: resourceTypeList,
                    //classifyId: curSubject,
                    //bookReleaseDate: curYear
                },
            }).then((res) => {
                if (res.data.code == 0) {
                    let curArray = res.data.data
                    curArray.forEach((e, ind) => {
                        if (e.typeName == '学科分类') {
                            _this.chartTotal1 = e.total
                            _this.chartData1 = e.conditions
                        } else if (e.typeName == '关键词') {
                            _this.chartData2 = e.conditions
                        } else if (e.typeName == '出版时间') {
                            var u = e.conditions
                            _this.chartData3 = u.reverse()
                        }
                    })
                }
            })
        },
        getInstro(id) {
            var _this = this
            this.$http({
                method: 'get',
                url: '/admin/api/news/list',
                params: {
                    innerCode: 'kfhq_gykfhq',
                    pageSize: 100,
                    pageNum: 1,
                },
            }).then((res) => {
                if (res.data.code == 0) {
                    _this.instroData = res.data.data.rows
                    _this.activeTitle = _this.instroData[0].title
                    _this.isUp = false
                    // _this.isDown = true
                    // var curobj1={
                    //     id: 222,
                    //     title: '456'
                    // }
                    // var curobj2={
                    //     id: 2223,
                    //     title: '456'
                    // }
                    // var curobj3={
                    //     id: 2224,
                    //     title: '456'
                    // }
                    // var curobj4={
                    //     id: 2225,
                    //     title: '456'
                    // }
                    // _this.instroData.push(curobj1)
                    // _this.instroData.push(curobj2)
                    // _this.instroData.push(curobj3)
                    // _this.instroData.push(curobj4)
                    if (_this.instroData.length > 5) {
                        _this.isDown = true
                    } else {
                        _this.isDown = false
                    }
                }
            })
        },
    },
    created() {
        if (this.$route.query.pN) {
            this.pN = decodeURI(this.$route.query.pN)
        } else {
            this.pN = ''
        }

        this.getData('kfhq_lbt') //轮播图
        this.getInstro()
        // 开放获取  文章和图书的 年份筛选参数  bookReleaseDate  ，find接口
        // 学科参数 使用  classifyId
        // 学科分类 接口  http://localhost:10667/admin/api/classify/xk     get
        // 把选中的学科的 id 传到 find 接口的 classifyId 字段上，要是支持多选，就是用 逗号隔开
        // 分类的筛选需要重建索引，现在还不能生效
        this.getListData('ARTICLE')
        this.getListData('BOOK')
        this.getData('kfhq_dt') //资讯动态
        this.getData('kfhq_sp') //OA视频
        this.getData('kfhq_sjj') //OA数据集
        this.getData('kfhq_kj') //OA课件
        //this.getData('kfhq_qtzy') //其他资源
        this.getData('kfhq_jj') //开放获取简介
        this.getPlatform()
        this.getResourceChart()
    },
    beforeDestroy() {
        clearInterval(this.timer)
        this.timer = null
    },
}
</script>


<style lang="scss" scoped>
.oa-page {
    .com-title {
        //border-bottom: none;
        border-bottom: 1px solid #eaeff3;
        div.title-div {
            position: relative;
            h5 {
                border-bottom: none;
                color: #2b221b;
                font-size: 22px;
                font-weight: bold;
            }
        }
        div.title-div::after {
            content: '';
            position: absolute;
            width: 20px;
            height: 10px;
            background: url(../assets/img/oa/icon-triangle.png);
            background-size: 100% 100%;
            left: 50%;
            transform: translateX(-50%);
            bottom: -10px;
        }
        span {
            display: flex;
            align-items: center;
            color: #777777;
            img {
                width: 20px;
                height: 20px;
                margin-left: 2px;
            }
        }
    }
    .introduction-paper {
        position: relative;
        min-height: 615px;
        z-index: 1;
        .top-bg {
            width: 100%;
            height: 323px;
            background: #eff4f8;
            position: absolute;
            z-index: -1;
        }
        .introduction-wrap {
            height: 440px;

            padding-top: 50px;

            box-sizing: border-box;
            margin-bottom: 34px;

            .wid1200 {
                position: relative;

                .left-introduction {
                    position: absolute;
                    left: 0;
                    top: 0;

                    z-index: 2;
                    padding-left: 10px;
                    .border-left-wrap {
                        z-index: -10;
                        position: absolute;
                        left: 0;
                        width: 44px;
                        height: 350px;
                        background: #c30d23;
                        padding-left: 10px;
                        border-radius: 40px 0px 0px 0px;
                        box-sizing: border-box;
                    }
                    .wid690 {
                        z-index: 100;
                        width: 699px;
                        border-radius: 40px 0px 0px 0px;
                        height: 380px;
                        overflow: hidden;
                        box-sizing: border-box;
                        position: relative;
                    }
                    .wid690::after {
                        content: '';
                        position: absolute;
                        right: 0;
                        top: 0;
                        width: 6px;
                        height: 350px;
                        background: #c30d23;
                    }
                }

                //<div class="left-introduction">
                //    <div class="border-left-wrap">
                //        <div class="border-left"></div>
                //    </div>
                //    <div class="wid690">
                //        <top-banner :bannerData="imgBannerData"></top-banner>
                //    </div>
                .right-introduction {
                    float: right;
                    width: 552px;
                    h6 {
                        padding-top: 40px;
                        padding-bottom: 24px;
                        font-size: 24px;
                        color: #828282;
                        line-height: 22px;
                        padding-left: 94px;
                    }
                    h5 {
                        color: #292929;
                        font-size: 32px;
                        margin-bottom: 34px;
                        line-height: 32px;
                        padding-left: 94px;
                    }
                    div {
                        width: 552px;
                        height: 230px;
                        background: #ffffff;
                        box-shadow: 0px 8px 20px 0px rgba(116, 126, 134, 0.1);
                        border-radius: 0px 0px 20px 0px;
                        padding: 40px 18px 0 90px;
                        box-sizing: border-box;
                        p {
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #828282;
                            line-height: 26px;
                        }
                        button {
                            margin-top: 18px;
                            float: right;
                            border: none;

                            height: 14px;
                            line-height: 14px;
                            padding: 0;
                            a {
                                //width: 49px;
                                display: flex;
                                font-size: 14px;
                                color: #777777;
                                background: #fff;
                                align-items: center;
                                img {
                                    width: 20px;
                                    height: 20px;
                                    margin-left: 2px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .article-wrap {
            //padding: 10px 30px 0;
            //margin: 26px auto;
            box-sizing: border-box;
            //border: 1px solid #f5f5f5;
            //box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            /*.com-title {
                //border-bottom: none;
                border-bottom: 1px solid #EAEFF3;
                div {
                    position: relative;
                    h5 {
                        border-bottom: none;
                        color: #2B221B;
                        font-size: 22px;
                    }

                }
                div.title-div::after {
                    content: '';
                    position: absolute;
                    width: 20px;
                    height: 10px;
                    background: url(../assets/img/oa/icon-triangle.png);
                    background-size: 100% 100%;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: -10px;
                }
                span {
                    display: flex;
                    align-items: center;
                    color: #777777;
                    img {
                        width: 20px;
                        height: 20px;
                        margin-left: 3px;
                    }
                }
            }*/

            ul.com-article {
                margin-top: 25px; //17
                margin-bottom: 24px;
                display: flex;
                flex-wrap: wrap;
                //background: url(../assets/img/border1.png);
                //background-size: 1px 50%;
                //background-repeat: repeat-y;
                //background-position: 50% 0%;
                li {
                    width: 574px;
                    border-bottom: 1px dashed #eaeff3;
                    display: flex;
                    .is-lock {
                        margin-right: 10px;
                        padding-top: 15px;
                        div {
                            img {
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                    .article-content {
                        display: block;
                        h5 {
                            margin-top: 15px;
                            line-height: 20px;
                            margin-bottom: 12px;
                            display: flex;
                            align-items: center;
                            width: 475px; //533px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            //img {
                            //    width: 16px;
                            //    height: 20px;
                            //    margin-right: 7px;
                            //}
                            p {
                                //width: 473px; //533px;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                font-size: 16px;
                                color: #2b221b;
                                margin-right: 7px;
                            }
                            span {
                                height: 20px;
                                line-height: 20px;
                                padding: 0 9px;
                                background: #eaeff3;
                                border-radius: 0px 10px 10px 10px;
                                color: #828282;
                            }
                        }
                        .cursor-h5 {
                            cursor: pointer;
                        }
                        div {
                            display: flex;
                            margin-bottom: 19px;
                            p {
                                color: #828282;
                                font-size: 14px;
                                line-height: 15px;
                                margin-right: 26px;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }
                            p.author {
                                width: 174px;
                            }
                            p.name {
                                width: 180px;
                            }

                            p.time {
                            }
                        }
                    }
                }
                li:nth-child(2n + 1) {
                    margin-right: 52px;
                }
                li:nth-child(2n + 2) {
                    margin-left: 33px;
                    width: 536px;
                    h5 {
                        width: 536px;
                    }
                }
                li:nth-last-child(2) {
                    margin-bottom: 0;
                }
                li:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .oa-news-video-data {
        background: #f1f7fb;
        padding: 28px 0;
        .wid1200 {
            display: flex;
            .oa-news,
            .oa-data {
                width: 388px;
                background: #fff;
                margin-right: 24px;
                padding: 10px 16px 20px;
                ul {
                    padding-top: 20px;
                }

                li {
                    width: 356px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    box-sizing: border-box;
                    //margin-bottom: 18px;
                    p {
                        position: relative;
                        width: 356px;
                        font-size: 14px;
                        line-height: 44px;
                        padding-left: 26px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        box-sizing: border-box;
                        a {
                            color: #2b221b;
                            cursor: pointer;
                        }
                    }
                    p::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 20px;
                        width: 6px;
                        height: 6px;
                        line-height: 18px;
                        border-radius: 50%;
                        background: #c30d23;
                    }
                    span {
                        display: block;
                        font-size: 18px;
                        width: 112px;
                        text-align: left;
                        margin-left: 10px;
                    }
                }
            }
            .oa-video {
                width: 388px;
                background: #fff;
                margin-right: 24px;
                padding: 10px 16px 20px;
                .oa-video-wrap {
                    video {
                        width: 356px;
                        height: 203px;
                        margin-top: 30px;
                        cursor: pointer;
                    }
                    img.cover-img {
                        width: 356px;
                        height: 203px;
                        margin-top: 30px;
                        cursor: pointer;
                    }
                    h6 {
                        font-size: 14px;
                        color: #2b221b;
                        line-height: 27px;
                        margin-top: 9px;
                        cursor: pointer;
                    }
                    p.date {
                        margin-top: 9px;
                        color: #828282;
                        font-size: 14px;
                        display: flex;
                        align-items: center;
                        img {
                            width: 19px;
                            margin-right: 6px;
                        }
                    }
                }
            }
            .oa-data {
                margin-right: 0;
            }
        }
    }
    //.top-banner-wrap {
    //    width: 100%;
    //    background: #cb2f3c;
    //    min-height: 300px;
    //}
    .oa-main {
        padding-top: 37px;
        .book-wrap {
            //padding: 10px 30px 0;
            //margin-bottom: 26px;
            box-sizing: border-box;
            //border: 1px solid #f5f5f5;
            //box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            .com-book-wrap {
                ul.com-book {
                    position: relative;
                    margin-top: 40px;
                    z-index: 0;
                    display: flex;
                    //flex-wrap: wrap;
                    li {
                        margin-right: 24px;
                        width: 180px;
                        margin-bottom: 15px;
                        img {
                            width: 180px;
                            height: 260px;
                            margin-bottom: 75px;
                            cursor: pointer;
                            box-shadow: 0px 15px 18px -15px #4f4a4a;
                        }
                        p {
                            color: #0d1020;
                            font-size: 16px;
                            width: 180px;
                            line-height: 16px;
                            text-align: center;
                            cursor: pointer;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                    li:last-child {
                        margin-right: 0;
                    }
                }
                ul.com-book::after {
                    content: '';
                    position: absolute;
                    width: 1380px;
                    height: 116px;
                    left: 50%;
                    bottom: 0;
                    transform: translateX(-50%);
                    z-index: -2;
                    background: url(../assets/img/oa/img-bookshelf.png);
                    background-size: 100% 100%;
                }
            }
        }
        .courseware-wrap {
            display: flex;
            margin-top: 43px;
            margin-bottom: 45px;
            .left-content {
                width: 928px;
                margin-right: 40px;
                ul {
                    padding-top: 16px;
                    li {
                        a {
                            width: 928px;
                            display: flex;
                            align-items: center;
                            font-size: 16px;
                            color: #2b221b;
                            line-height: 57px;
                            border-bottom: 1px dashed #eaeff3;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            cursor: pointer;
                            span {
                                background: #bd1a2d;
                                width: 26px;
                                height: 26px;
                                line-height: 26px;
                                color: #fff;
                                font-size: 10px;
                                text-align: center;
                                border-radius: 4px;
                                margin-right: 6px;
                            }
                            //img {
                            //    width: 30px;
                            //    margin-right: 11px;
                            //}
                        }
                    }
                }
            }
            .right-button {
                padding-top: 10px;
                width: 230px;
                height: 400px;
                button {
                    width: 240px;
                    height: 30px;
                    background: #fafbfc;
                    border: none;
                    border-radius: 4px;
                    margin-bottom: 10px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 14px;
                    }
                }
                .down-btn {
                    margin-bottom: 0;
                    margin-top: 10px;
                }
                .button-wrap {
                    width: 240px;
                    max-height: 320px;
                    overflow: hidden;
                    position: relative;
                    .button-ul {
                        transition: 1s;
                    }
                }
                ul {
                    li {
                        width: 240px;
                        height: 56px;
                        font-size: 20px;
                        color: #0d1020;
                        line-height: 56px;
                        margin-bottom: 10px;
                        text-align: center;
                        background: url(../assets/img/oa/bg-link-default.png);
                        background-size: 100% 100%;
                        cursor: pointer;
                        a {
                            display: block;
                            width: 240px;
                            height: 56px;
                            color: #0d1020;
                            line-height: 56px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            box-sizing: border-box;
                            padding: 0 10px;
                        }
                    }
                    li:hover {
                        color: #fff;
                        background: url(../assets/img/oa/bg-link-selected.png);
                        background-size: 100% 100%;
                        a {
                            color: #fff;
                        }
                    }
                }
            }
        }
        .visual-analysis-wrap {
            h6 {
                padding-left: 18px;
                color: #2b221b;
                font-size: 18px;
                font-weight: bold;
                position: relative;
                line-height: 18px;
                margin-top: 20px;
            }
            h6::after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 6px;
                height: 18px;
                background: #c30d23;
            }
            .top-chart {
                display: flex;
                justify-content: space-between;
                margin-top: 40px;
                margin-bottom: 20px;
                .left-resourceDisciplineDistributionMap,
                .right-word-cloud-chart {
                    width: 590px;
                    height: 390px;
                    //padding: 20px 20px 40px 20px;
                    border-radius: 4px;
                    border: 1px solid #eaeff3;
                    .right-word-cloud-chart-wrap {
                        overflow: hidden;
                        width: 590px;
                        height: 350px;
                    }
                }
            }
            .bot-chart {
                width: 100%;
                height: 400px;
                border-radius: 4px;
                border: 1px solid #eaeff3;
                margin-bottom: 38px;
            }
        }

        .oa-platform-wrap {
            ul {
                display: flex;
                flex-wrap: wrap;
                margin-top: 40px;
                li {
                    width: 230px;
                    margin-right: 12px;
                    margin-bottom: 20px;
                    a {
                        display: block;
                        position: relative;
                        z-index: 0;
                        cursor: pointer;
                        img {
                            display: block;
                            width: 230px;
                            height: 140px;
                        }
                        h6 {
                            line-height: 40px;
                            background: #f5f7f9;
                            font-size: 20px;
                            font-weight: bold;
                            color: #2b221b;
                            text-align: center;
                            width: 230px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            box-sizing: border-box;
                            padding: 0 10px;
                        }
                        p {
                            position: absolute;
                            width: 230px;
                            height: 180px;
                            left: 0;
                            top: 0;

                            background: rgba(0, 0, 0, 0.7);
                            z-index: 2;
                            padding: 30px 25px 0 25px;
                            cursor: pointer;
                            display: none;
                            span {
                                font-size: 14px;
                                color: #fff;
                                line-height: 24px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 5; // 控制多行的行数
                                -webkit-box-orient: vertical;
                                box-sizing: border-box;
                            }
                        }
                    }
                    a:hover {
                        p {
                            display: block;
                        }
                    }
                }
                li:nth-child(5n + 5) {
                    margin-right: 0;
                }
            }
        }
    }
}

.pagination-wrap {
    display: flex;
    align-items: center;
    .total-page {
        font-size: 14px;
        color: #333 !important;
        margin-right: 4px;
    }
}
</style>
